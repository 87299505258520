<section class="login-form" fxLayout fxLayoutAlign="center">
  <div class="overlay" fxLayout fxLayoutAlign="center" *ngIf="isLoading">
    <mat-progress-spinner mode="indeterminate" diameter="100"></mat-progress-spinner>
  </div>
  <mat-card fxFlex.xs="100%" fxFlex="400px">
    <mat-card-title fxLayoutAlign="center">Bejelentkezés</mat-card-title>
    <mat-card-content fxLayoutAlign="center">
      <form fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" #loginForm="ngForm"
            (ngSubmit)="onSubmit(loginForm)">
        <mat-form-field>
          <input type="text" matInput placeholder="Felhasználónév" ngModel name="username" required>
          <mat-error>A felhasználónevet kötelező megadni.</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="password" matInput placeholder="Jelszó" ngModel name="password" required>
          <mat-error>A jelszót kötelező megadni.</mat-error>
        </mat-form-field>
        <button type="submit" mat-raised-button color="primary" [disabled]="loginForm.invalid">Bejelentkezés</button>
      </form>
    </mat-card-content>
  </mat-card>
</section>

<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation">
    <app-sidenav (closeSidenav)="sidenav.close()"></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

interface IUser {
  id: number;
  username: string;
  email: string;
  roles: string[];
}

export class User implements IUser {
  public id: number;
  public username: string;
  public email: string;
  public roles: string[];

  constructor(user?: IUser) {
    this.id = user && user.id || null;
    this.username = user && user.username || null;
    this.email = user && user.email || null;
    this.roles = user && user.roles || null;
  }

  hasRole(role: string): boolean {
    return this.roles.indexOf(role) !== -1;
  }
}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isLoading = false;

  constructor(private authService: AuthService, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  onSubmit(loginForm: NgForm): void {
    if (!loginForm.valid) {
      return;
    }

    this.isLoading = true;

    this.authService.login(loginForm.value.username, loginForm.value.password).subscribe(
      () => {
        this.authService.getMe().subscribe(resUser => {
          this.isLoading = false;
          this.router.navigate(['/']);
        });
      },
      errorMessage => {
        this.snackBar.open(errorMessage, 'Bezár', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['red-snackbar']
        });
        this.isLoading = false;
      },
    );

    loginForm.resetForm();
  }
}

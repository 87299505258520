import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { Passenger } from '../shared/models/passenger.model';

@Injectable({
  providedIn: 'root'
})
export class PassengersService {

  constructor(private http: HttpClient) { }

  getPassengers(sort: string, order: string, page: number, filter: string): Observable<any> {
    let url = environment.server.apiUrl + '/passengers?sort=' + sort + '&order=' + order + '&page=' + (page + 1);
    url += (filter) ? '&name=' + filter : '';
    return this.http.get<Passenger>(url);
  }
}

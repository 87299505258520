import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { HeaderComponent } from './shared/navigation/header/header.component';
import { SidenavComponent } from './shared/navigation/sidenav/sidenav.component';
import { HomeComponent } from './shared/layout/home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { TripsComponent } from './trips/trips.component';
import { TripstableComponent } from './trips/tripstable/tripstable.component';
import { PassengersComponent } from './passengers/passengers.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavComponent,
    HomeComponent,
    LoginComponent,
    TripsComponent,
    TripstableComponent,
    PassengersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

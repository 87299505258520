import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../shared/models/user.model';
import { Token } from '../shared/models/token.model';

export interface AuthResponseData {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

@Injectable({providedIn: 'root'})
export class AuthService {
  user = new BehaviorSubject<User>(null);

  private authToken: Token;

  constructor(private http: HttpClient, private router: Router) {
  }

  login(username: string, password: string): Observable<any> {
    return this.http
      .post<AuthResponseData>(
        environment.server.apiUrl + '/auth/login',
        {
          username: username,
          password: password
        }
      )
      .pipe(
        map(resData => {
          this.storeAuthToken(resData);
          return this.authToken;
        }),
        catchError(this.handleError),
      );
  }

  autoLogin(): void {
    const tmpToken: Token = JSON.parse(localStorage.getItem('userData'));

    if (!tmpToken) {
      return;
    }

    this.authToken = tmpToken;
  }

  refreshToken(): Observable<any> {
    return this.http
      .post<AuthResponseData>(
        environment.server.apiUrl + '/auth/refresh', {
          refresh_token: this.authToken.refresh_token
        }
      )
      .pipe(
        map(resData => {
          this.storeAuthToken(resData);
          return this.authToken;
        }),
        catchError(this.handleError)
      );
  }

  logout(): void {
    this.user.next(null);
    localStorage.removeItem('userData');
    this.router.navigate(['/login']);
  }

  getMe(): Observable<User> {
    if (this.user.value) {
      return of(this.user.value);
    } else {
      return this.http.get<User>(environment.server.apiUrl + '/me').pipe(
        map(resData => {
          this.user.next(new User(resData));
          return this.user.value;
        })
      );
    }
  }

  private handleError(error: HttpErrorResponse): any {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      errorMessage = error.error.error;
    }
    return throwError(errorMessage);
  }

  private storeAuthToken(data: AuthResponseData): void {
    this.authToken = new Token(data.token_type, data.access_token, data.refresh_token);
    localStorage.setItem('userData', JSON.stringify(this.authToken));
  }

  getAuthToken(): Token {
    return this.authToken;
  }
}

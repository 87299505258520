import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

import { parsePhoneNumber, isPossiblePhoneNumber, AsYouType } from 'libphonenumber-js';

import { Passenger } from '../shared/models/passenger.model';
import { PassengersService } from './passengers.service';

@Component({
  selector: 'app-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: ['./passengers.component.css']
})
export class PassengersComponent implements OnInit, AfterViewInit {
  isLoading = true;
  data: Passenger[];
  displayedColumns: string[] = ['name', 'address', 'phone', 'email'];
  resultsLength = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  constructor(private passService: PassengersService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    fromEvent(this.input.nativeElement, 'keyup').pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(() => {
        this.paginator.pageIndex = 0;
        return this.loadPage();
      }),
      catchError(() => {
        this.isLoading = false;
        return of([]);
      })
    ).subscribe();

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(
      startWith({}),
      switchMap(() => this.loadPage()),
      catchError(() => {
        this.isLoading = false;
        return of([]);
      })
    ).subscribe();
  }

  private loadPage(): Observable<any> {
    this.isLoading = true;
    return this.passService.getPassengers(
      this.sort.active, this.sort.direction, this.paginator.pageIndex, this.input.nativeElement.value).pipe(
      map(resData => {
        this.isLoading = false;
        this.data = resData.data;
        this.resultsLength = resData.total;
      })
    );
  }

  showPhoneNumber(phone: string): string {
    // return new AsYouType('HU').input(phone);

    return (isPossiblePhoneNumber(phone, 'HU')) ? parsePhoneNumber(phone, 'HU').formatInternational() : '';
  }
}

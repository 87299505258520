import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Trips, TripsService } from './trips.service';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.css']
})
export class TripsComponent implements OnInit, AfterViewInit {
  isLoading = true;
  actTrips: Trips[];
  closedTrips: Trips[];

  constructor(private trips: TripsService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.trips.getTrips().subscribe(resData => {
      this.isLoading = false;
      this.actTrips = resData.data.filter(trip => trip.active !== 0);
      this.closedTrips = resData.data.filter(trip => trip.active === 0);
    });
  }
}

<mat-toolbar color="primary">
  <div fxHide.gt-xs>
        <button mat-icon-button (click)="onToggleSidenav()">
          <mat-icon>menu</mat-icon>
        </button>
  </div>
  <div class="navigation-items">
      <a mat-list-item routerLink="/">VTravel</a>
  </div>
  <div fxFlex fxLayout fxHide.xs>
    <mat-nav-list fxLayout="row" class="navigation-items">
      <a mat-list-item routerLink="/utazasok" class="navigation-items" *ngIf="user">Utazások</a>
      <a mat-list-item routerLink="/utasok" class="navigation-items" *ngIf="user">Utasok</a>
      <a mat-list-item routerLink="/admin" class="navigation-items" *ngIf="user && user.hasRole('admins')">Adminisztráció</a>
    </mat-nav-list>
  </div>
  <div fxFlex fxLayout fxLayoutAlign="flex-end" fxHide.xs>
    <button mat-icon-button [matMenuTriggerFor]="account" aria-label="Fiók" *ngIf="user">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #account="matMenu">
      <button mat-menu-item routerLink="/profile">
        <mat-icon>settings</mat-icon>
        <span>Beállítások</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="onLogout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Kijelentkezés</span>
      </button>
    </mat-menu>
    <button mat-icon-button routerLink="/login" *ngIf="!user">
      <mat-icon>login</mat-icon>
    </button>
  </div>
</mat-toolbar>

import { Component, Input, OnInit } from '@angular/core';
import { Trips } from '../trips.service';

@Component({
  selector: 'app-tripstable',
  templateUrl: './tripstable.component.html',
  styleUrls: ['./tripstable.component.css']
})
export class TripstableComponent implements OnInit {
  @Input() data: Trips[];
  displayedColumns: string[] = ['pos_number', 'description', 'date_start', 'settings'];

  constructor() { }

  ngOnInit(): void {
  }

  onRowClick(row): void {
    console.log(row);
  }

  onEdit(id: number): void {
    console.log(id);
  }
}

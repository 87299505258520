import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

export interface Trips {
  id: number;
  unique_id: number;
  pos_number: string;
  destination: string;
  description: string;
  date_start: string;
  date_end: string;
  price: number;
  active: number;
}

@Injectable({
  providedIn: 'root'
})
export class TripsService {

  constructor(private http: HttpClient) { }

  getTrips(): Observable<any> {
    return this.http.get<Trips>(environment.server.apiUrl + '/trips');
  }
}

<mat-nav-list>
  <a mat-list-item routerLink="/login" (click)="onClose()" *ngIf="!user">
    <mat-icon>login</mat-icon>
    <span class="nav-caption">Bejelentkezés</span>
  </a>
  <a mat-list-item routerLink="/utazasok" (click)="onClose()" *ngIf="user">
    <mat-icon>airport_shuttle</mat-icon>
    <span class="nav-caption">Utazások</span>
  </a>
  <a mat-list-item routerLink="/utasok" (click)="onClose()" *ngIf="user">
    <mat-icon>fact_check</mat-icon>
    <span class="nav-caption">Utasok</span>
  </a>
  <a mat-list-item routerLink="/admin" (click)="onClose()" *ngIf="user && user.hasRole('admins')">
    <mat-icon>build</mat-icon>
    <span class="nav-caption">Adminisztráció</span>
  </a>
  <mat-divider></mat-divider>
  <mat-list-item>
    <button mat-icon-button (click)="onLogout()" *ngIf="user">
      <mat-icon>exit_to_app</mat-icon>
      <span class="nav-caption">Kijelentkezés</span>
    </button>
  </mat-list-item>
</mat-nav-list>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {HomeComponent} from './shared/layout/home/home.component';
import {LoginComponent} from './auth/login/login.component';
import {AuthGuard} from './auth/auth.guard';
import { TripsComponent } from './trips/trips.component';
import { PassengersComponent } from './passengers/passengers.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'utazasok', component: TripsComponent, canActivate: [AuthGuard]},
  { path: 'utasok', component: PassengersComponent, canActivate: [AuthGuard]},
  { path: 'admin', component: TripsComponent, canActivate: [AuthGuard], data: { role: 'admins'}},
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }

<div class="trips-container">
  <div class="loading-shade" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </div>
  <mat-tab-group>
    <mat-tab label="Aktuális utazások">
      <app-tripstable [data]="actTrips"></app-tripstable>
    </mat-tab>
    <mat-tab label="Lezárt utazások">
      <app-tripstable [data]="closedTrips"></app-tripstable>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="passengers-container">
  <div class="loading-shade" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </div>
  <div class="search-container">
  <mat-form-field>
    <mat-label>Keresés</mat-label>
    <input matInput placeholder="Keresés" #input>
  </mat-form-field>
  <mat-paginator [length]="resultsLength" [pageSize]="15" showFirstLastButtons></mat-paginator>
  </div>
  <div class="passengers-table-container">
    <mat-table [dataSource]="data" class="mat-elevation-z8" matSort matSortActive="name" matSortDirection="asc">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Név</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef fxShow fxHide.xs>Cím</mat-header-cell>
        <mat-cell *matCellDef="let row" fxShow fxHide.xs>{{row.zip}} {{row.city}}, {{row.address}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef>Telefon</mat-header-cell>
        <mat-cell *matCellDef="let row">{{showPhoneNumber(row.phone)}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.email}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Nincs találat!</td>
      </tr>
    </mat-table>
  </div>

</div>

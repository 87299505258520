<div class="trip-table-container">
  <mat-table [dataSource]="data" class="trips-table">
    <ng-container matColumnDef="pos_number">
      <mat-header-cell *matHeaderCellDef>Pozicószám</mat-header-cell>
      <mat-cell *matCellDef="let row" (click)="onRowClick(row)">{{row.pos_number}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>Leírás</mat-header-cell>
      <mat-cell *matCellDef="let row" (click)="onRowClick(row)">{{row.description}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="date_start">
      <mat-header-cell *matHeaderCellDef>Időpont</mat-header-cell>
      <mat-cell *matCellDef="let row" (click)="onRowClick(row)">{{row.date_start}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="settings">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row" style="text-align: right">
        <button mat-icon-button [mat-menu-trigger-for]="trip">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #trip="matMenu">
          <button mat-menu-item (click)="onEdit(row.id)">
            <mat-icon>settings</mat-icon>
            <span>Szerkesztés</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
